<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: center; align-center; font-family: 'Poppins-SemiBold'"
      >
        <div class="mt-8">
          <v-img src="@/assets/svg/congrates.svg" />
        </div>
      </v-card-title>
      <v-card-text
        style="font-family:'Poppins-SemiBold'; font-size: 16px; color: #0A5B8A;"
        class="d-flex justify-center text-center mt-5"
      >
        Congratulations!<br />
        You've set up your account.
      </v-card-text>
      <v-card-text
        style="font-family: 'Poppins-Regular'; font-size: 14px; color: #919191;"
        class="px-8"
      >
        We're going to send you an email just to make sure that your email
        address is working.
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn color="#b5e539" @click="onClose" width="150">
          Continue
        </v-btn>
      </v-card-actions>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-text
        style="color:#919191; font-style: 'Poppins-Regular'; font-size: 14px;"
        class="mt-0 d-flex flex-row align-center pb-8 px-8"
      >
        Didn't receive the email?
        <v-btn
          text
          color="#0A5B8A"
          class="text-transform-none url-btn"
          link
          @click="onSendEmail"
          :loading="loading"
        >
          Send it again.
        </v-btn>
      </v-card-text>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      message: null,
      loading: false,
      snackbar: false,
      errorMessage: null,
      isEmailSent: false,
    };
  },
  methods: {
    ...mapActions("agency", {
      sendEmailVerification: "sendEmailVerification",
    }),
    onChange() {
      this.message = null;
    },
    selectDay(item) {
      this.selectedDay = item;
    },
    onSetStartTime(startTime) {
      this.startTimeStr = startTime;
      this.start_time_picker = false;
    },
    onSetEndTime(endTime) {
      this.endTimeStr = endTime;
      this.end_time_picker = false;
    },
    getDay(schedule) {
      return schedule.split(" ")[0];
    },
    getScheduleTime(schedule) {
      return schedule.substring(4);
    },
    onRemoveSchedule(schedule) {
      this.schedules = this.schedules.filter((s) => s != schedule);
    },
    onSendEmail() {
      this.loading = true;
      this.sendEmailVerification()
        .then(() => {
          this.isEmailSent = true;
          this.loading = false;
        })
        .catch((error) => {
          this.showSnackbar(error.response.data.message);
        });
    },
    showSnackbar(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    selectedDay() {
      this.startTimeStr = "";
      this.endTimeStr = "";
    },
  },
  mounted() {
    this.loading = true;
    this.sendEmailVerification()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.response.data.message);
      });
  },
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.selected-card {
  background: #b5e539;
}
div :deep(.v-card:focus::before) {
  border-radius: 12px;
}
.url-btn:focus::before {
  opacity: 0;
}
.url-btn:hover::before {
  opacity: 0;
}
.url-btn:focus {
  text-decoration: underline;
}
.url-btn:hover {
  text-decoration: underline;
}
</style>
